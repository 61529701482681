// Load Standard Components
if (document.querySelector('.js-nav')) {
    import(/* webpackChunkName: "global" */ './components/init-sticky-nav');
    import(/* webpackChunkName: "global" */ './components/init-nav-dropdown');
}
if (document.querySelector('.js-search')) {
    import(/* webpackChunkName: "global" */ './components/init-search');
}
if (document.querySelector('.js-burger')) {
    import(/* webpackChunkName: "global" */ './components/init-mobile-nav');
}
if (document.querySelector('.js-cookie-bar')) {
    import(/* webpackChunkName: "global" */ './components/init-cookie-bar');
}
if (document.querySelector('[data-anim]')) {
    import(/* webpackChunkName: "global" */ './components/init-netc-aos');
}
if (document.querySelector('[data-anim-svg]')) {
    import(/* webpackChunkName: "global" */ './components/init-animate-svg');
}
if (document.querySelector('.js-review-carousel')) {
    import('./components/init-review-carousel');
}
if (document.querySelector('.js-success-stories')) {
    import('./components/init-success-stories');
}
if (document.querySelector('.js-awards-carousel')) {
    import('./components/init-awards-carousel');
}
if (document.querySelector('.js-video-play')) {
    import('./components/init-modal-video');
}
if (document.querySelector('.js-accordion')) {
    import('./components/init-accordion');
}
if (document.querySelector('.js-tabbed-media')) {
    import('./components/init-tabbed-media');
}
if (document.querySelector('.js-testimonial-carousel')) {
    import('./components/init-testimonial-carousel');
}
if (document.querySelector('.js-go-to-section')) {
    import('./components/init-scroll');
}
if (document.querySelector('.js-employer-input')) {
    import('./components/init-employerdropdown');
}
if (document.querySelector('.js-check-page')) {
    import('./components/init-check-page');
}
if (document.querySelector('.js-culture-selector')) {
    import('./components/init-culture-selector');
}
if (document.querySelector('.homepage-top-banner')) {
    import('./components/init-homepage-top-banner-close');
}
// Load react only if we have components on the page.
if (document.querySelector('[data-component]')) {
    import(/* webpackChunkName: "app" */ './react');
}
// Load Learn subdomain specific Amplitude tracking.
if (document.querySelector('body[data-learn]')) {
    import(/* webpackChunkName: "global" */ './components/init-amplitude-learn');
}
else {
    import(/* webpackChunkName: "global" */ './components/init-amplitude');
}
